import { ITextCell } from 'app/containers/AbstractTable/types';
import { TextWithMods } from 'app/components/TextWithMods';
import { Typography } from 'app/components/Typography';
import { Box } from 'app/components/Box';
import { Tooltip } from 'app/components/Tooltip';
import { Icon } from 'app/components/Icon';
import { useTheme } from '@mui/material';

export const CellText = ({ data }: ITextCell) => {
  const { text, mods, info } = data;

  const theme = useTheme();

  if (info) {
    return (
      <Box display="flex" alignItems="center" gap=".5rem">
        <Typography variant="body2" noWrap={mods?.includes('nowrap')}>
          <TextWithMods mods={mods || []}>{text}</TextWithMods>
        </Typography>
        <Tooltip
          title={
            <Typography
              variant="caption"
              color="inherit"
              maxWidth="20rem"
              component="p"
            >
              {info}
            </Typography>
          }
        >
          <Box display="flex" alignItems="center">
            <Icon
              name="info"
              width="1.2rem"
              height="1.2rem"
              color={theme.palette.info.main}
            />
          </Box>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Typography variant="body2" noWrap={mods?.includes('nowrap')}>
      <TextWithMods mods={mods || []}>{text}</TextWithMods>
    </Typography>
  );
};
